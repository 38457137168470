const dv_icons_list = [
    "fal fa-address-book",
    "fal fa-address-card",
    "fal fa-adjust",
    "fal fa-alarm-clock",
    "fal fa-align-center",
    "fal fa-align-justify",
    "fal fa-align-left",
    "fal fa-align-right",
    "fal fa-allergies",
    "fal fa-ambulance",
    "fal fa-american-sign-language-interpreting",
    "fal fa-anchor",
    "fal fa-angle-double-down",
    "fal fa-angle-double-left",
    "fal fa-angle-double-right",
    "fal fa-angle-double-up",
    "fal fa-angle-down",
    "fal fa-angle-left",
    "fal fa-angle-right",
    "fal fa-angle-up",
    "fal fa-archive",
    "fal fa-arrow-alt-circle-down",
    "fal fa-arrow-alt-circle-left",
    "fal fa-arrow-alt-circle-right",
    "fal fa-arrow-alt-circle-up",
    "fal fa-arrow-alt-down",
    "fal fa-arrow-alt-from-bottom",
    "fal fa-arrow-alt-from-left",
    "fal fa-arrow-alt-from-right",
    "fal fa-arrow-alt-from-top",
    "fal fa-arrow-alt-left",
    "fal fa-arrow-alt-right",
    "fal fa-arrow-alt-square-down",
    "fal fa-arrow-alt-square-left",
    "fal fa-arrow-alt-square-right",
    "fal fa-arrow-alt-square-up",
    "fal fa-arrow-alt-to-bottom",
    "fal fa-arrow-alt-to-left",
    "fal fa-arrow-alt-to-right",
    "fal fa-arrow-alt-to-top",
    "fal fa-arrow-alt-up",
    "fal fa-arrow-circle-down",
    "fal fa-arrow-circle-left",
    "fal fa-arrow-circle-right",
    "fal fa-arrow-circle-up",
    "fal fa-arrow-down",
    "fal fa-arrow-from-bottom",
    "fal fa-arrow-from-left",
    "fal fa-arrow-from-right",
    "fal fa-arrow-from-top",
    "fal fa-arrow-left",
    "fal fa-arrow-right",
    "fal fa-arrow-square-down",
    "fal fa-arrow-square-left",
    "fal fa-arrow-square-right",
    "fal fa-arrow-square-up",
    "fal fa-arrow-to-bottom",
    "fal fa-arrow-to-left",
    "fal fa-arrow-to-right",
    "fal fa-arrow-to-top",
    "fal fa-arrow-up",
    "fal fa-arrows",
    "fal fa-arrows-alt",
    "fal fa-arrows-alt-h",
    "fal fa-arrows-alt-v",
    "fal fa-arrows-h",
    "fal fa-arrows-v",
    "fal fa-assistive-listening-systems",
    "fal fa-asterisk",
    "fal fa-at",
    "fal fa-audio-description",
    "fal fa-backward",
    "fal fa-badge",
    "fal fa-badge-check",
    "fal fa-balance-scale",
    "fal fa-ban",
    "fal fa-band-aid",
    "fal fa-barcode",
    "fal fa-barcode-alt",
    "fal fa-barcode-read",
    "fal fa-barcode-scan",
    "fal fa-bars",
    "fal fa-baseball",
    "fal fa-baseball-ball",
    "fal fa-basketball-ball",
    "fal fa-basketball-hoop",
    "fal fa-bath",
    "fal fa-battery-bolt",
    "fal fa-battery-empty",
    "fal fa-battery-full",
    "fal fa-battery-half",
    "fal fa-battery-quarter",
    "fal fa-battery-slash",
    "fal fa-battery-three-quarters",
    "fal fa-bed",
    "fal fa-beer",
    "fal fa-bell",
    "fal fa-bell-slash",
    "fal fa-bicycle",
    "fal fa-binoculars",
    "fal fa-birthday-cake",
    "fal fa-blanket",
    "fal fa-blind",
    "fal fa-bold",
    "fal fa-bolt",
    "fal fa-bomb",
    "fal fa-book",
    "fal fa-book-heart",
    "fal fa-bookmark",
    "fal fa-bowling-ball",
    "fal fa-bowling-pins",
    "fal fa-box",
    "fal fa-box-alt",
    "fal fa-box-check",
    "fal fa-box-fragile",
    "fal fa-box-full",
    "fal fa-box-heart",
    "fal fa-box-open",
    "fal fa-box-up",
    "fal fa-box-usd",
    "fal fa-boxes",
    "fal fa-boxes-alt",
    "fal fa-boxing-glove",
    "fal fa-braille",
    "fal fa-briefcase",
    "fal fa-briefcase-medical",
    "fal fa-browser",
    "fal fa-bug",
    "fal fa-building",
    "fal fa-bullhorn",
    "fal fa-bullseye",
    "fal fa-burn",
    "fal fa-bus",
    "fal fa-calculator",
    "fal fa-calendar",
    "fal fa-calendar-alt",
    "fal fa-calendar-check",
    "fal fa-calendar-edit",
    "fal fa-calendar-exclamation",
    "fal fa-calendar-minus",
    "fal fa-calendar-plus",
    "fal fa-calendar-times",
    "fal fa-camera",
    "fal fa-camera-alt",
    "fal fa-camera-retro",
    "fal fa-capsules",
    "fal fa-car",
    "fal fa-caret-circle-down",
    "fal fa-caret-circle-left",
    "fal fa-caret-circle-right",
    "fal fa-caret-circle-up",
    "fal fa-caret-down",
    "fal fa-caret-left",
    "fal fa-caret-right",
    "fal fa-caret-square-down",
    "fal fa-caret-square-left",
    "fal fa-caret-square-right",
    "fal fa-caret-square-up",
    "fal fa-caret-up",
    "fal fa-cart-arrow-down",
    "fal fa-cart-plus",
    "fal fa-certificate",
    "fal fa-chart-area",
    "fal fa-chart-bar",
    "fal fa-chart-line",
    "fal fa-chart-pie",
    "fal fa-check",
    "fal fa-check-circle",
    "fal fa-check-square",
    "fal fa-chess",
    "fal fa-chess-bishop",
    "fal fa-chess-bishop-alt",
    "fal fa-chess-board",
    "fal fa-chess-clock",
    "fal fa-chess-clock-alt",
    "fal fa-chess-king",
    "fal fa-chess-king-alt",
    "fal fa-chess-knight",
    "fal fa-chess-knight-alt",
    "fal fa-chess-pawn",
    "fal fa-chess-pawn-alt",
    "fal fa-chess-queen",
    "fal fa-chess-queen-alt",
    "fal fa-chess-rook",
    "fal fa-chess-rook-alt",
    "fal fa-chevron-circle-down",
    "fal fa-chevron-circle-left",
    "fal fa-chevron-circle-right",
    "fal fa-chevron-circle-up",
    "fal fa-chevron-double-down",
    "fal fa-chevron-double-left",
    "fal fa-chevron-double-right",
    "fal fa-chevron-double-up",
    "fal fa-chevron-down",
    "fal fa-chevron-left",
    "fal fa-chevron-right",
    "fal fa-chevron-square-down",
    "fal fa-chevron-square-left",
    "fal fa-chevron-square-right",
    "fal fa-chevron-square-up",
    "fal fa-chevron-up",
    "fal fa-child",
    "fal fa-circle",
    "fal fa-circle-notch",
    "fal fa-clipboard",
    "fal fa-clipboard-check",
    "fal fa-clipboard-list",
    "fal fa-clock",
    "fal fa-clone",
    "fal fa-closed-captioning",
    "fal fa-cloud",
    "fal fa-cloud-download",
    "fal fa-cloud-download-alt",
    "fal fa-cloud-upload",
    "fal fa-cloud-upload-alt",
    "fal fa-club",
    "fal fa-code",
    "fal fa-code-branch",
    "fal fa-code-commit",
    "fal fa-code-merge",
    "fal fa-coffee",
    "fal fa-cog",
    "fal fa-cogs",
    "fal fa-columns",
    "fal fa-comment",
    "fal fa-comment-alt",
    "fal fa-comment-alt-check",
    "fal fa-comment-alt-dots",
    "fal fa-comment-alt-edit",
    "fal fa-comment-alt-exclamation",
    "fal fa-comment-alt-lines",
    "fal fa-comment-alt-minus",
    "fal fa-comment-alt-plus",
    "fal fa-comment-alt-slash",
    "fal fa-comment-alt-smile",
    "fal fa-comment-alt-times",
    "fal fa-comment-check",
    "fal fa-comment-dots",
    "fal fa-comment-edit",
    "fal fa-comment-exclamation",
    "fal fa-comment-lines",
    "fal fa-comment-minus",
    "fal fa-comment-plus",
    "fal fa-comment-slash",
    "fal fa-comment-smile",
    "fal fa-comment-times",
    "fal fa-comments",
    "fal fa-comments-alt",
    "fal fa-compass",
    "fal fa-compress",
    "fal fa-compress-alt",
    "fal fa-compress-wide",
    "fal fa-container-storage",
    "fal fa-conveyor-belt",
    "fal fa-conveyor-belt-alt",
    "fal fa-copy",
    "fal fa-copyright",
    "fal fa-couch",
    "fal fa-credit-card",
    "fal fa-credit-card-blank",
    "fal fa-credit-card-front",
    "fal fa-cricket",
    "fal fa-crop",
    "fal fa-crosshairs",
    "fal fa-cube",
    "fal fa-cubes",
    "fal fa-curling",
    "fal fa-cut",
    "fal fa-database",
    "fal fa-deaf",
    "fal fa-desktop",
    "fal fa-desktop-alt",
    "fal fa-diagnoses",
    "fal fa-diamond",
    "fal fa-dna",
    "fal fa-dollar-sign",
    "fal fa-dolly",
    "fal fa-dolly-empty",
    "fal fa-dolly-flatbed",
    "fal fa-dolly-flatbed-alt",
    "fal fa-dolly-flatbed-empty",
    "fal fa-donate",
    "fal fa-dot-circle",
    "fal fa-dove",
    "fal fa-download",
    "fal fa-dumbbell",
    "fal fa-edit",
    "fal fa-eject",
    "fal fa-ellipsis-h",
    "fal fa-ellipsis-h-alt",
    "fal fa-ellipsis-v",
    "fal fa-ellipsis-v-alt",
    "fal fa-envelope",
    "fal fa-envelope-open",
    "fal fa-envelope-square",
    "fal fa-eraser",
    "fal fa-euro-sign",
    "fal fa-exchange",
    "fal fa-exchange-alt",
    "fal fa-exclamation",
    "fal fa-exclamation-circle",
    "fal fa-exclamation-square",
    "fal fa-exclamation-triangle",
    "fal fa-expand",
    "fal fa-expand-alt",
    "fal fa-expand-arrows",
    "fal fa-expand-arrows-alt",
    "fal fa-expand-wide",
    "fal fa-external-link",
    "fal fa-external-link-alt",
    "fal fa-external-link-square",
    "fal fa-external-link-square-alt",
    "fal fa-eye",
    "fal fa-eye-dropper",
    "fal fa-eye-slash",
    "fal fa-fast-backward",
    "fal fa-fast-forward",
    "fal fa-fax",
    "fal fa-female",
    "fal fa-field-hockey",
    "fal fa-fighter-jet",
    "fal fa-file",
    "fal fa-file-alt",
    "fal fa-file-archive",
    "fal fa-file-audio",
    "fal fa-file-check",
    "fal fa-file-code",
    "fal fa-file-edit",
    "fal fa-file-excel",
    "fal fa-file-exclamation",
    "fal fa-file-image",
    "fal fa-file-medical",
    "fal fa-file-medical-alt",
    "fal fa-file-minus",
    "fal fa-file-pdf",
    "fal fa-file-plus",
    "fal fa-file-powerpoint",
    "fal fa-file-times",
    "fal fa-file-video",
    "fal fa-file-word",
    "fal fa-film",
    "fal fa-film-alt",
    "fal fa-filter",
    "fal fa-fire",
    "fal fa-fire-extinguisher",
    "fal fa-first-aid",
    "fal fa-flag",
    "fal fa-flag-checkered",
    "fal fa-flask",
    "fal fa-folder",
    "fal fa-folder-open",
    "fal fa-font",
    "fal fa-football-ball",
    "fal fa-football-helmet",
    "fal fa-forklift",
    "fal fa-forward",
    "fal fa-fragile",
    "fal fa-frown",
    "fal fa-futbol",
    "fal fa-gamepad",
    "fal fa-gavel",
    "fal fa-gem",
    "fal fa-genderless",
    "fal fa-gift",
    "fal fa-glass-martini",
    "fal fa-globe",
    "fal fa-golf-ball",
    "fal fa-golf-club",
    "fal fa-graduation-cap",
    "fal fa-h-square",
    "fal fa-h1",
    "fal fa-h2",
    "fal fa-h3",
    "fal fa-hand-heart",
    "fal fa-hand-holding",
    "fal fa-hand-holding-box",
    "fal fa-hand-holding-heart",
    "fal fa-hand-holding-seedling",
    "fal fa-hand-holding-usd",
    "fal fa-hand-holding-water",
    "fal fa-hand-lizard",
    "fal fa-hand-paper",
    "fal fa-hand-peace",
    "fal fa-hand-point-down",
    "fal fa-hand-point-left",
    "fal fa-hand-point-right",
    "fal fa-hand-point-up",
    "fal fa-hand-pointer",
    "fal fa-hand-receiving",
    "fal fa-hand-rock",
    "fal fa-hand-scissors",
    "fal fa-hand-spock",
    "fal fa-hands",
    "fal fa-hands-heart",
    "fal fa-hands-helping",
    "fal fa-hands-usd",
    "fal fa-handshake",
    "fal fa-handshake-alt",
    "fal fa-hashtag",
    "fal fa-hdd",
    "fal fa-heading",
    "fal fa-headphones",
    "fal fa-heart",
    "fal fa-heart-circle",
    "fal fa-heart-square",
    "fal fa-heartbeat",
    "fal fa-hexagon",
    "fal fa-history",
    "fal fa-hockey-puck",
    "fal fa-hockey-sticks",
    "fal fa-home",
    "fal fa-home-heart",
    "fal fa-hospital",
    "fal fa-hospital-alt",
    "fal fa-hospital-symbol",
    "fal fa-hourglass",
    "fal fa-hourglass-end",
    "fal fa-hourglass-half",
    "fal fa-hourglass-start",
    "fal fa-i-cursor",
    "fal fa-id-badge",
    "fal fa-id-card",
    "fal fa-id-card-alt",
    "fal fa-image",
    "fal fa-images",
    "fal fa-inbox",
    "fal fa-inbox-in",
    "fal fa-inbox-out",
    "fal fa-indent",
    "fal fa-industry",
    "fal fa-industry-alt",
    "fal fa-info",
    "fal fa-info-circle",
    "fal fa-info-square",
    "fal fa-inventory",
    "fal fa-italic",
    "fal fa-jack-o-lantern",
    "fal fa-key",
    "fal fa-keyboard",
    "fal fa-lamp",
    "fal fa-language",
    "fal fa-laptop",
    "fal fa-leaf",
    "fal fa-leaf-heart",
    "fal fa-lemon",
    "fal fa-level-down",
    "fal fa-level-down-alt",
    "fal fa-level-up",
    "fal fa-level-up-alt",
    "fal fa-life-ring",
    "fal fa-lightbulb",
    "fal fa-link",
    "fal fa-lira-sign",
    "fal fa-list",
    "fal fa-list-alt",
    "fal fa-list-ol",
    "fal fa-list-ul",
    "fal fa-location-arrow",
    "fal fa-lock",
    "fal fa-lock-alt",
    "fal fa-lock-open",
    "fal fa-lock-open-alt",
    "fal fa-long-arrow-alt-down",
    "fal fa-long-arrow-alt-left",
    "fal fa-long-arrow-alt-right",
    "fal fa-long-arrow-alt-up",
    "fal fa-long-arrow-down",
    "fal fa-long-arrow-left",
    "fal fa-long-arrow-right",
    "fal fa-long-arrow-up",
    "fal fa-loveseat",
    "fal fa-low-vision",
    "fal fa-luchador",
    "fal fa-magic",
    "fal fa-magnet",
    "fal fa-male",
    "fal fa-map",
    "fal fa-map-marker",
    "fal fa-map-marker-alt",
    "fal fa-map-pin",
    "fal fa-map-signs",
    "fal fa-mars",
    "fal fa-mars-double",
    "fal fa-mars-stroke",
    "fal fa-mars-stroke-h",
    "fal fa-mars-stroke-v",
    "fal fa-medkit",
    "fal fa-meh",
    "fal fa-mercury",
    "fal fa-microchip",
    "fal fa-microphone",
    "fal fa-microphone-alt",
    "fal fa-microphone-slash",
    "fal fa-minus",
    "fal fa-minus-circle",
    "fal fa-minus-hexagon",
    "fal fa-minus-octagon",
    "fal fa-minus-square",
    "fal fa-mobile",
    "fal fa-mobile-alt",
    "fal fa-mobile-android",
    "fal fa-mobile-android-alt",
    "fal fa-money-bill",
    "fal fa-money-bill-alt",
    "fal fa-moon",
    "fal fa-motorcycle",
    "fal fa-mouse-pointer",
    "fal fa-music",
    "fal fa-neuter",
    "fal fa-newspaper",
    "fal fa-notes-medical",
    "fal fa-object-group",
    "fal fa-object-ungroup",
    "fal fa-octagon",
    "fal fa-outdent",
    "fal fa-paint-brush",
    "fal fa-pallet",
    "fal fa-pallet-alt",
    "fal fa-paper-plane",
    "fal fa-paperclip",
    "fal fa-parachute-box",
    "fal fa-paragraph",
    "fal fa-paste",
    "fal fa-pause",
    "fal fa-pause-circle",
    "fal fa-paw",
    "fal fa-pen",
    "fal fa-pen-alt",
    "fal fa-pen-square",
    "fal fa-pencil",
    "fal fa-pencil-alt",
    "fal fa-pennant",
    "fal fa-people-carry",
    "fal fa-percent",
    "fal fa-person-carry",
    "fal fa-person-dolly",
    "fal fa-person-dolly-empty",
    "fal fa-phone",
    "fal fa-phone-plus",
    "fal fa-phone-slash",
    "fal fa-phone-square",
    "fal fa-phone-volume",
    "fal fa-piggy-bank",
    "fal fa-pills",
    "fal fa-plane",
    "fal fa-plane-alt",
    "fal fa-play",
    "fal fa-layer-group",
    "fal fa-play-circle",
    "fal fa-plug",
    "fal fa-plus",
    "fal fa-plus-circle",
    "fal fa-plus-hexagon",
    "fal fa-plus-octagon",
    "fal fa-plus-square",
    "fal fa-podcast",
    "fal fa-poo",
    "fal fa-portrait",
    "fal fa-pound-sign",
    "fal fa-power-off",
    "fal fa-prescription-bottle",
    "fal fa-prescription-bottle-alt",
    "fal fa-print",
    "fal fa-procedures",
    "fal fa-puzzle-piece",
    "fal fa-qrcode",
    "fal fa-question",
    "fal fa-question-circle",
    "fal fa-question-square",
    "fal fa-quidditch",
    "fal fa-quote-left",
    "fal fa-quote-right",
    "fal fa-racquet",
    "fal fa-ramp-loading",
    "fal fa-random",
    "fal fa-rectangle-landscape",
    "fal fa-rectangle-portrait",
    "fal fa-rectangle-wide",
    "fal fa-recycle",
    "fal fa-redo",
    "fal fa-redo-alt",
    "fal fa-registered",
    "fal fa-repeat",
    "fal fa-repeat-1",
    "fal fa-repeat-1-alt",
    "fal fa-repeat-alt",
    "fal fa-reply",
    "fal fa-reply-all",
    "fal fa-retweet",
    "fal fa-retweet-alt",
    "fal fa-ribbon",
    "fal fa-road",
    "fal fa-rocket",
    "fal fa-route",
    "fal fa-rss",
    "fal fa-rss-square",
    "fal fa-ruble-sign",
    "fal fa-rupee-sign",
    "fal fa-save",
    "fal fa-scanner",
    "fal fa-scanner-keyboard",
    "fal fa-scanner-touchscreen",
    "fal fa-scrubber",
    "fal fa-search",
    "fal fa-search-minus",
    "fal fa-search-plus",
    "fal fa-seedling",
    "fal fa-server",
    "fal fa-share",
    "fal fa-share-all",
    "fal fa-share-alt",
    "fal fa-share-alt-square",
    "fal fa-share-square",
    "fal fa-shekel-sign",
    "fal fa-shield",
    "fal fa-shield-alt",
    "fal fa-shield-check",
    "fal fa-ship",
    "fal fa-shipping-fast",
    "fal fa-shipping-timed",
    "fal fa-shopping-bag",
    "fal fa-shopping-basket",
    "fal fa-shopping-cart",
    "fal fa-shower",
    "fal fa-shuttlecock",
    "fal fa-sign",
    "fal fa-sign-in",
    "fal fa-sign-in-alt",
    "fal fa-sign-language",
    "fal fa-sign-out",
    "fal fa-sign-out-alt",
    "fal fa-signal",
    "fal fa-sitemap",
    "fal fa-sliders-h",
    "fal fa-sliders-h-square",
    "fal fa-sliders-v",
    "fal fa-sliders-v-square",
    "fal fa-smile",
    "fal fa-smile-plus",
    "fal fa-smoking",
    "fal fa-snowflake",
    "fal fa-sort",
    "fal fa-sort-alpha-down",
    "fal fa-sort-alpha-up",
    "fal fa-sort-amount-down",
    "fal fa-sort-amount-up",
    "fal fa-sort-down",
    "fal fa-sort-numeric-down",
    "fal fa-sort-numeric-up",
    "fal fa-sort-up",
    "fal fa-space-shuttle",
    "fal fa-spade",
    "fal fa-spinner",
    "fal fa-spinner-third",
    "fal fa-square",
    "fal fa-square-full",
    "fal fa-star",
    "fal fa-star-exclamation",
    "fal fa-star-half",
    "fal fa-step-backward",
    "fal fa-step-forward",
    "fal fa-stethoscope",
    "fal fa-sticky-note",
    "fal fa-stop",
    "fal fa-stop-circle",
    "fal fa-stopwatch",
    "fal fa-street-view",
    "fal fa-strikethrough",
    "fal fa-subscript",
    "fal fa-subway",
    "fal fa-suitcase",
    "fal fa-sun",
    "fal fa-superscript",
    "fal fa-sync",
    "fal fa-sync-alt",
    "fal fa-syringe",
    "fal fa-table",
    "fal fa-table-tennis",
    "fal fa-tablet",
    "fal fa-tablet-alt",
    "fal fa-tablet-android",
    "fal fa-tablet-android-alt",
    "fal fa-tablet-rugged",
    "fal fa-tablets",
    "fal fa-tachometer",
    "fal fa-tachometer-alt",
    "fal fa-tag",
    "fal fa-tags",
    "fal fa-tape",
    "fal fa-tasks",
    "fal fa-taxi",
    "fal fa-tennis-ball",
    "fal fa-terminal",
    "fal fa-text-height",
    "fal fa-text-width",
    "fal fa-th",
    "fal fa-th-large",
    "fal fa-th-list",
    "fal fa-thermometer",
    "fal fa-thermometer-empty",
    "fal fa-thermometer-full",
    "fal fa-thermometer-half",
    "fal fa-thermometer-quarter",
    "fal fa-thermometer-three-quarters",
    "fal fa-thumbs-down",
    "fal fa-thumbs-up",
    "fal fa-thumbtack",
    "fal fa-ticket",
    "fal fa-ticket-alt",
    "fal fa-times",
    "fal fa-times-circle",
    "fal fa-times-hexagon",
    "fal fa-times-octagon",
    "fal fa-times-square",
    "fal fa-tint",
    "fal fa-toggle-off",
    "fal fa-toggle-on",
    "fal fa-trademark",
    "fal fa-train",
    "fal fa-transgender",
    "fal fa-transgender-alt",
    "fal fa-trash",
    "fal fa-trash-alt",
    "fal fa-tree",
    "fal fa-tree-alt",
    "fal fa-triangle",
    "fal fa-trophy",
    "fal fa-trophy-alt",
    "fal fa-truck",
    "fal fa-truck-container",
    "fal fa-truck-couch",
    "fal fa-truck-loading",
    "fal fa-truck-moving",
    "fal fa-truck-ramp",
    "fal fa-tty",
    "fal fa-tv",
    "fal fa-tv-retro",
    "fal fa-umbrella",
    "fal fa-underline",
    "fal fa-undo",
    "fal fa-undo-alt",
    "fal fa-universal-access",
    "fal fa-university",
    "fal fa-unlink",
    "fal fa-unlock",
    "fal fa-unlock-alt",
    "fal fa-upload",
    "fal fa-usd-circle",
    "fal fa-usd-square",
    "fal fa-user",
    "fal fa-user-alt",
    "fal fa-user-circle",
    "fal fa-user-md",
    "fal fa-user-plus",
    "fal fa-user-secret",
    "fal fa-user-times",
    "fal fa-users",
    "fal fa-utensil-fork",
    "fal fa-utensil-knife",
    "fal fa-utensil-spoon",
    "fal fa-utensils",
    "fal fa-utensils-alt",
    "fal fa-venus",
    "fal fa-venus-double",
    "fal fa-venus-mars",
    "fal fa-vial",
    "fal fa-vials",
    "fal fa-video",
    "fal fa-video-plus",
    "fal fa-video-slash",
    "fal fa-volleyball-ball",
    "fal fa-volume-down",
    "fal fa-volume-mute",
    "fal fa-volume-off",
    "fal fa-volume-up",
    "fal fa-warehouse",
    "fal fa-warehouse-alt",
    "fal fa-watch",
    "fal fa-weight",
    "fal fa-wheelchair",
    "fal fa-whistle",
    "fal fa-wifi",
    "fal fa-window",
    "fal fa-window-alt",
    "fal fa-window-close",
    "fal fa-window-maximize",
    "fal fa-window-minimize",
    "fal fa-window-restore",
    "fal fa-wine-glass",
    "fal fa-won-sign",
    "fal fa-wrench",
    "fal fa-x-ray"
];
export default dv_icons_list;
